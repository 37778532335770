import React, { Component } from 'react'
import { Form, Button, Row, Col, Input, Select, AutoComplete } from 'antd'
import MaskedInput from 'antd-mask-input'
import { pureMsisdn, regExpCity, nexignMsisdn } from '../../utils'
import CitiesApi from '../../api/CitiesApi'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Message from '../../locale/Message'
import RegistrationApi from '../../api/RegistrationApi'
import * as _ from 'underscore'
import useWindowSize from '../../utils/useWindowSize'

const { Option } = Select
class NonResidentRegistration extends Component {
  state = {
    channel: 'ICS',
    msisdn: '',
    firstName: '',
    lastName: '',
    middleName: '',
    docNumber: '',
    gender: 'MALE',
    cityId: null,
    cities: [],
    allCities: [],
    isLoading: false,
    isFieldsValid: false,
    sender: 'activ',
    isActivMsisdn: false,
    hasError: false,
    errorCode: null,
  }

  componentDidMount() {
    this.getCitiesInfo()
  }

  getCitiesInfo = () => {
    const { lang } = this.props

    CitiesApi.getCities()
      .then(({ data }) => {
        this.setState({
          allCities: data.map((city) => {
            const regionNameRu = city.region.name.ru
            const regionNameKk = city.region.name.kk
              ? city.region.name.kk
              : regionNameRu
            const regionNameEn = city.region.name.en
              ? city.region.name.en
              : regionNameRu

            return (
              <Option key={city.cityId}>
                {lang === 'ru'
                  ? city.name.ru + ' (' + regionNameRu + ')'
                  : lang === 'en'
                  ? city.name.en + ' (' + regionNameEn + ')'
                  : city.name.kk + ' (' + regionNameKk + ')'}
              </Option>
            )
          }),
        })
      })
      .catch((err) => {
        console.log('getCities error', err.response)
      })
  }

  /**
   * Метод проверки брэнда  и оператора
   */
  mnpLookUpByMsisdn = () => {
    const { msisdn } = this.state
    this.setState({ isLoading: true })

    RegistrationApi.mnpLookUpByMsisdn(nexignMsisdn(msisdn))
      .then(({ data }) => {
        const { billing } = data
        const new_billings = ['ACTIV_NEXIGN', 'KCELL_NEXIGN']
        const old_billings = ['GOLD_PREPAID']

        // Если номера находятся в биллинге nexign
        if (new_billings.includes(billing)) {
          this.setState({
            msisdn: msisdn,
            isActivMsisdn: true,
            hasError: false,
            errorCode: null,
            isLoading: false,
          })

          this.generateOtp()
        }
        // Если номера старые и находятся в биллинге coda
        else if (old_billings.includes(billing)) {
          this.setState({
            msisdn: msisdn,
            isActivMsisdn: true,
            hasError: false,
            errorCode: null,
            isLoading: false,
          })

          this.props.setThatIsOldBilling(true)
          this.generateOtp()
        } else {
          this.setState(
            {
              isActivMsisdn: false,
              hasError: true,
              errorCode: 'ORG_REGISTERED',
              isLoading: false,
            },
            () => {
              this.props.setStep(
                'message',
                msisdn,
                '',
                this.state.hasError,
                this.state.errorCode
              )
            }
          )
        }
      })
      .catch(({ response }) => {
        this.setState(
          {
            isActivMsisdn: false,
            hasError: true,
            errorCode: response && response.data.code,
            isLoading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              '',
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
  }

  generateOtp = async () => {
    const {
      msisdn,
      cityId,
      gender,
      docNumber,
      lastName,
      firstName,
      middleName,
      channel,
    } = this.state
    const { executeRecaptcha, lang } = this.props
    const token = await executeRecaptcha('msisdn_registration')
    const data = {
      msisdn,
      cityId,
      gender,
      docNumber,
      lastName,
      firstName,
      middleName,
      channel,
    }

    RegistrationApi.generateOtp(msisdn, token, 'SEND_SMS', lang)
      .then((response) => {
        console.log('generateOtp response', response)
        this.props.setStep(
          'smsCode',
          msisdn,
          '',
          this.state.hasError,
          this.state.errorCode,
          data
        )
      })
      .catch((response) => {
        console.log('generateOtp error', response)
      })
  }

  onChangeInput = (e) => {
    const { name, value } = e.target

    this.setState(
      {
        [name]: name === 'msisdn' ? pureMsisdn(value) : value,
      },
      () => {
        this.isFieldsValid()
      }
    )
  }

  onChangeGender = (value) => {
    this.setState(
      {
        gender: value,
      },
      () => {
        this.isFieldsValid()
      }
    )
  }

  onChangeCity = (value) => {
    this.setState(
      {
        cityId: value,
      },
      () => {
        this.isFieldsValid()
      }
    )
  }

  isFieldsValid = () => {
    const { msisdn, firstName, lastName, docNumber, cityId } = this.state
    let isValid =
      msisdn &&
      msisdn.length === 11 &&
      firstName &&
      firstName.length > 0 &&
      lastName &&
      lastName.length > 0 &&
      docNumber &&
      docNumber.length > 0 &&
      cityId &&
      cityId.length > 0

    this.setState({
      isFieldsValid: isValid,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.mnpLookUpByMsisdn()
  }

  handleSearch = _.debounce((inputValue) => {
    const { allCities } = this.state
    let cities
    if (!inputValue) {
      cities = []
    } else {
      cities = allCities.filter((item) => {
        const stringToMatch = item.props.children.replace(regExpCity, '')
        return stringToMatch.toUpperCase().match(inputValue.toUpperCase())
      })
    }
    this.setState({ cities })
  }, 500).bind(this)

  render() {
    const { cities, isLoading, isFieldsValid } = this.state
    const { lang } = this.props

    return (
      <div>
        <Row>
          <Col xs={24} sm={24} md={8}>
            <h1 className="mb-24">
              {Message.nonresidentNumberRegistration[lang]}
            </h1>
          </Col>
        </Row>
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <MaskedInput
                  autoFocus
                  mask="111 111 11 11"
                  type="tel"
                  placeholder={Message.inputPhoneNumber[lang]}
                  addonBefore="+7"
                  name="msisdn"
                  onChange={this.onChangeInput}
                />
                <p className="text-small text-gray mb-0">
                  {Message.PhoneNumberForSMS[lang]}
                </p>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <Input
                  type="text"
                  placeholder={Message.firstName[lang]}
                  name="firstName"
                  maxLength={20}
                  onChange={this.onChangeInput}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <Input
                  type="text"
                  placeholder={Message.lastName[lang]}
                  name="lastName"
                  maxLength={20}
                  onChange={this.onChangeInput}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <Input
                  type="text"
                  placeholder={Message.middleName[lang]}
                  name="middleName"
                  maxLength={20}
                  onChange={this.onChangeInput}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <Input
                  type="text"
                  placeholder={Message.docNumber[lang]}
                  name="docNumber"
                  maxLength={20}
                  onChange={this.onChangeInput}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <Select
                  name="gender"
                  defaultValue="MALE"
                  onChange={this.onChangeGender}
                >
                  <Option value="MALE">{Message.male[lang]}</Option>
                  <Option value="FEMALE">{Message.female[lang]}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <AutoComplete
                  name="cityId"
                  placeholder={Message.city[lang]}
                  allowClear={true}
                  onSelect={this.onChangeCity}
                  onSearch={this.handleSearch}
                  dropdownAlign={
                    this.props.windowWidth < 768 && {
                      points: ['bl', 'tl'], // align dropdown bottom-left to top-left of input element
                      offset: [0, -4], // align offset
                      overflow: {
                        adjustX: 0,
                        adjustY: 0, // do not auto flip in y-axis
                      },
                    }
                  }
                >
                  {cities}
                </AutoComplete>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={!isFieldsValid}
                  className="check-msisdn-btn send-otp-btn"
                >
                  {Message.next[lang]}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

const NonResidentRegistrationWrapper = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { width } = useWindowSize()
  return (
    <NonResidentRegistration
      {...props}
      executeRecaptcha={executeRecaptcha}
      windowWidth={width}
    />
  )
}

export default NonResidentRegistrationWrapper
