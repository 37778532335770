import React, { Component } from 'react'
import { Button, Row, Col } from 'antd'
import { msisdnWithMask, nexignMsisdn } from '../../utils'
import RegistrationApi from '../../api/RegistrationApi'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Message from '../../locale/Message'

class NewRegistration extends Component {
  state = {
    hasError: false,
    errorCode: null,
    isLoading: false,
  }

  // old billing
  registerNumber = async () => {
    this.setState({
      isLoading: true,
    })

    const { msisdn, iin, executeRecaptcha } = this.props
    const token = await executeRecaptcha('new_registration')

    RegistrationApi.register(msisdn, iin, token)
      .then((response) => {
        console.log('response', response)
        this.setState(
          {
            hasError: false,
            errorCode: null,
            isloading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              iin,
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
      .catch(({ response }) => {
        console.log('response', response)
        this.setState(
          {
            hasError: true,
            errorCode: '',
            isloading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              iin,
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
  }

  // new billing
  subscriberSelfRegistrationResident = async () => {
    this.setState({
      isLoading: true,
    })

    const { msisdn, iin, executeRecaptcha } = this.props
    const token = await executeRecaptcha('new_registration')

    RegistrationApi.subscriberSelfRegistrationResident(
      nexignMsisdn(msisdn),
      iin,
      token
    )
      .then((response) => {
        console.log('response', response)
        this.setState(
          {
            hasError: false,
            errorCode: null,
            isloading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              iin,
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
      .catch(({ response }) => {
        console.log('response', response)
        this.setState(
          {
            hasError: true,
            errorCode: '',
            isloading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              iin,
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { isOldBilling } = this.props
    if (isOldBilling) this.registerNumber()
    else this.subscriberSelfRegistrationResident()
  }

  render() {
    const { msisdn, iin, lang } = this.props
    const { isLoading } = this.state

    return (
      <Row className="correct-code">
        <Col xs={24} sm={24} md={8}>
          <h1 className="mb-24">{Message.IINRegistration[lang]}</h1>
          <p>
            {Message.phoneNumber[lang]}: {msisdnWithMask(msisdn)}.
          </p>
          <p className="mb-24">
            {Message.IIN[lang]}: {iin}.
          </p>
          <Button
            block
            type="primary"
            onClick={this.handleSubmit}
            className="registration-btn mb-16"
            loading={isLoading}
          >
            {Message.register[lang]}
          </Button>
          <p className="text-small text-dark-gray mb-24">
            {Message.confirmationPublicTerms[lang]}
          </p>
        </Col>
      </Row>
    )
  }
}

const NewRegistrationWrapper = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  return <NewRegistration {...props} executeRecaptcha={executeRecaptcha} />
}

export default NewRegistrationWrapper
