import BaseApi from './BaseApi'

export default class ImeiApi extends BaseApi {
  static async getMsisdnInfo(msisdn) {
    return this.get(`/rest/msisdn?msisdn=${msisdn}`)
  }

  static async generateOtp(msisdn, token, messageType, locale) {
    return this.post('/rest/otp/generate', {
      msisdn,
      token,
      messageType,
      locale,
    })
  }

  static async checkOtp(msisdn, token, code) {
    return this.post('/rest/otp/check', {
      msisdn,
      token,
      code,
    })
  }

  static async checkRegistration(msisdn, iin, token) {
    return this.post('/rest/selfreg/check', {
      msisdn,
      iin,
      token,
    })
  }

  static async register(msisdn, iin, token) {
    return this.post('/rest/selfreg', {
      msisdn,
      iin,
      token,
    })
  }

  static async reregister(msisdn, iin, token, calls) {
    return this.put('/rest/selfreg', {
      msisdn,
      iin,
      token,
      calls,
    })
  }

  static async nonresidentRegister(
    msisdn,
    cityId,
    gender,
    docNumber,
    lastName,
    firstName,
    middleName,
    channel,
    token
  ) {
    return this.post('/rest/selfreg/nonresident', {
      msisdn,
      cityId,
      gender,
      docNumber,
      lastName,
      firstName,
      middleName,
      channel,
      token,
    })
  }
  /** NEW NEXIGN METHODS */
  /**
   * Метод проверки брэнда и оператора
   * @param {number} msisdn - номер
   */
  static async mnpLookUpByMsisdn(msisdn) {
    return this.get('/rest/msisdn/' + msisdn)
  }
  /**
   * Метод проверки статуса номера (возвращается только статус абонента)
   * @param {number} msisdn
   */
  static async getMsisdnStateByMsisdn(msisdn) {
    return this.get('/rest/msisdn-state?msisdn=' + msisdn)
  }
  /**
   * Метод позволяющий проверять доступность номера к перерегистрации
   * @param {number} msisdn
   * @param {string} iin
   */
  static async isSelfReregistrationEligible(msisdn, iin) {
    return this.get('/rest/is-self-registration', { msisdn, iin })
  }
  /**
   * Метод позволяющий произвести самостоятельную перерегистрацию абонента резидента
   * @param {number} msisdn
   * @param {string} iin
   * @param {Array} msisdnsToCheck
   * @param {string} token
   */
  static async subscriberSelfReregistration(
    msisdn,
    iin,
    msisdnsToCheck,
    token
  ) {
    return this.post('/rest/re-registration', {
      msisdn,
      iin,
      msisdnsToCheck,
      token,
      channel: 'ICS',
      notify: true,
    })
  }
  /**
   * Метод позволяющий произвести самостоятельную регистрацию абонента резидента
   * @param {number} msisdn
   * @param {string} iin
   * @param {string} token
   */
  static async subscriberSelfRegistrationResident(msisdn, iin, token) {
    return this.post('/rest/resident', {
      msisdn,
      iin,
      token,
      channel: 'ICS',
      isImeiRegRequired: true,
      isTempRegAllowed: true,
      notify: true,
    })
  }
  /**
   * Метод позволяющий произвести самостоятельную регистрацию не резидента
   * @param {string} msisdn
   * @param {string} documentNumber
   * @param {boolean} firstName
   * @param {boolean} lastName
   * @param {boolean} middleName
   * @param {boolean} gender
   * @param {boolean} city
   * @param {boolean} token
   */
  static async subscriberSelfRegistrationNonResident(
    msisdn,
    documentNumber,
    token,
    firstName,
    lastName,
    middleName,
    gender,
    city
  ) {
    return this.post('/rest/nonresident', {
      msisdn,
      documentNumber,
      token,
      firstName,
      lastName,
      middleName,
      gender,
      city,
      channel: 'ICS',
      isImeiRegRequired: true,
      notify: true,
    })
  }
  /** NEW NEXIGN METHODS */
}
