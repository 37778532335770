import React, { Component } from 'react'
import { Form, Button, Row, Col, notification } from 'antd'
import MaskedInput from 'antd-mask-input'
import { nexignMsisdn, pureMsisdn } from '../../utils'
import RegistrationApi from '../../api/RegistrationApi'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Message from '../../locale/Message'

class MsisdnRegistration extends Component {
  state = {
    msisdn: '',
    iin: '',
    isActivMsisdn: false,
    hasError: false,
    errorCode: null,
    isLoading: false,
    sender: 'activ',
    message: '[]',
  }

  openNotification = (className, message, description) => {
    notification.error({
      className,
      top: 64,
      message,
      description,
      placement: 'topRight',
    })
  }

  /**
   * Метод проверки брэнда  и оператора
   */
  mnpLookUpByMsisdn = () => {
    const { msisdn, iin } = this.state
    this.setState({ isLoading: true })

    RegistrationApi.mnpLookUpByMsisdn(nexignMsisdn(msisdn))
      .then(({ data }) => {
        const { billing } = data
        const new_billings = ['ACTIV_NEXIGN', 'KCELL_NEXIGN']
        const old_billings = ['GOLD_PREPAID']

        // Если номера находятся в биллинге nexign
        if (new_billings.includes(billing)) {
          this.setState({
            msisdn: msisdn,
            isActivMsisdn: true,
            hasError: false,
            errorCode: null,
            isLoading: false,
          })

          this.generateOtp()
        }
        // Если номера старые и находятся в биллинге coda
        else if (old_billings.includes(billing)) {
          this.setState({
            msisdn: msisdn,
            isActivMsisdn: true,
            hasError: false,
            errorCode: null,
            isLoading: false,
          })

          this.props.setThatIsOldBilling(true)
          this.generateOtp()
        } else {
          this.setState(
            {
              isActivMsisdn: false,
              hasError: true,
              errorCode: 'ORG_REGISTERED',
              isLoading: false,
            },
            () => {
              this.props.setStep(
                'message',
                msisdn,
                iin,
                this.state.hasError,
                this.state.errorCode
              )
            }
          )
        }
      })
      .catch(({ response }) => {
        this.setState(
          {
            isActivMsisdn: false,
            hasError: true,
            errorCode: response && response.data.code,
            isLoading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              iin,
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
  }

  generateOtp = async () => {
    const { msisdn, iin } = this.state
    const { executeRecaptcha, lang } = this.props
    const token = await executeRecaptcha('msisdn_registration')

    RegistrationApi.generateOtp(msisdn, token, 'SEND_SMS', lang)
      .then((response) => {
        console.log('generateOtp response', response)
        this.props.setStep(
          'smsCode',
          msisdn,
          iin,
          this.state.hasError,
          this.state.errorCode
        )
      })
      .catch((response) => {
        console.log('generateOtp error', response)
        this.openNotification(
          'code-not-sent',
          Message.systemErrorTitle[lang],
          Message.tryLater[lang]
        )
      })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.mnpLookUpByMsisdn()
  }

  onChangeInput = (e) => {
    const { name, value } = e.target
    let formValue

    if (name === 'iin') {
      formValue = value.replace(/[^0-9]/g, '')
    } else if (name === 'msisdn') {
      formValue = pureMsisdn(value)
    }

    this.setState({
      [name]: formValue,
    })
  }

  render() {
    const { msisdn, iin, isLoading } = this.state
    const { lang } = this.props

    return (
      <>
        <Row>
          <Col xs={24} sm={24} md={16}>
            <h1 className="mb-24">{Message.numberRegistration[lang]}</h1>
            <p className="mb-24">{Message.selfIINRegistration[lang]}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={10}>
            <Form onSubmit={this.handleSubmit}>
              <Form.Item>
                <MaskedInput
                  autoFocus
                  mask="111 111 11 11"
                  type="tel"
                  placeholder={Message.inputPhoneNumber[lang]}
                  addonBefore="+7"
                  name="msisdn"
                  onChange={this.onChangeInput}
                />
                <p className="text-small text-gray mb-0">
                  {Message.PhoneNumberForSMS[lang]}
                </p>
              </Form.Item>
              <Form.Item>
                <MaskedInput
                  mask="111111111111"
                  type="tel"
                  placeholder={Message.inputIIN[lang]}
                  name="iin"
                  onChange={this.onChangeInput}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={!(msisdn.length === 11 && iin.length === 12)}
                  className="check-msisdn-btn send-otp-btn"
                >
                  {Message.next[lang]}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    )
  }
}

const MsisdnRegistrationWrapper = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  return <MsisdnRegistration {...props} executeRecaptcha={executeRecaptcha} />
}

export default MsisdnRegistrationWrapper
