import React, { Component } from 'react'
import { Form, Button, Row, Col, Statistic, notification } from 'antd'
import MaskedInput from 'antd-mask-input'
import { msisdnWithMask, nexignMsisdn } from '../../utils'
import RegistrationApi from '../../api/RegistrationApi'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Message from '../../locale/Message'

const { Countdown } = Statistic

class SmsCode extends Component {
  state = {
    code: '',
    hasError: false,
    errorCode: null,
    isLoading: false,
    isResendCodeAllowed: false,
    resendSMSIn: Date.now() + 1000 * 61,
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.checkOtp()
  }

  onFinish = () => {
    this.setState({
      isResendCodeAllowed: true,
    })
  }

  openNotification = (type, className, message, description) => {
    notification[type]({
      className,
      top: 64,
      message,
      description,
      placement: 'topRight',
    })
  }

  resendCode = async () => {
    const { msisdn, executeRecaptcha, lang } = this.props
    const token = await executeRecaptcha('sms_code')

    RegistrationApi.generateOtp(msisdn, token, 'RESEND_SMS', lang)
      .then(() => {
        console.log('resendCode success')
        this.setState({
          isResendCodeAllowed: false,
          resendSMSIn: Date.now() + 1000 * 61,
        })
      })
      .catch(() => {
        console.log('resendCode error')
        this.openNotification(
          'error',
          'code-not-sent',
          Message.systemErrorTitle[lang],
          Message.tryLater[lang]
        )
      })
  }

  onChangeSmsCode = (e) => {
    const value = e.target.value

    this.setState({
      code: value,
    })
  }

  checkOtp = async () => {
    this.setState({
      isLoading: true,
    })

    const { code } = this.state
    const { msisdn, iin, executeRecaptcha, lang, clientType, isOldBilling } =
      this.props
    const token = await executeRecaptcha('check_otp')

    RegistrationApi.checkOtp(msisdn, token, code)
      .then(({ data }) => {
        const { exists } = data

        if (exists) {
          if (clientType === 'resident') {
            if (isOldBilling) this.checkRegistration()
            else this.getMsisdnStateByMsisdn()
          } else if (clientType === 'non-resident') {
            if (isOldBilling) this.nonresidentRegister()
            else this.getMsisdnStateByMsisdnNonResident()
          }
        } else {
          this.openNotification(
            'warning',
            'incorrect-code',
            Message.attention[lang],
            Message.wrongSMSCode[lang]
          )
          this.setState({
            isLoading: false,
          })
        }
      })
      .catch(({ response }) => {
        console.log('checkOtp response', response)
        this.setState(
          {
            hasError: true,
            errorCode: '',
            isLoading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              iin,
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
  }

  nonresidentRegister = async () => {
    const { data, executeRecaptcha } = this.props
    const {
      msisdn,
      cityId,
      gender,
      docNumber,
      lastName,
      firstName,
      middleName,
      channel,
    } = data
    const token = await executeRecaptcha('nonresident_register')

    RegistrationApi.nonresidentRegister(
      msisdn,
      cityId,
      gender,
      docNumber,
      lastName,
      firstName,
      middleName,
      channel,
      token
    )
      .then((response) => {
        console.log('nonresidentRegister response', response)
        this.props.setStep(
          'message',
          msisdn,
          '',
          this.state.hasError,
          this.state.errorCode
        )
      })
      .catch((response) => {
        console.log('nonresidentRegister error', response)
        this.setState(
          {
            hasError: true,
            errorCode: 'FAILED',
            isLoading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              '',
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
  }

  isSelfReregistrationEligible = async () => {
    const { msisdn, iin } = this.props

    RegistrationApi.isSelfReregistrationEligible(nexignMsisdn(msisdn), iin)
      .then(() => {
        this.setState(
          {
            hasError: false,
            errorCode: '',
            isLoading: false,
          },
          () => {
            this.props.setStep(
              'reRegistration',
              msisdn,
              iin,
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
      .catch(({ response }) => {
        this.setState(
          {
            hasError: true,
            errorCode: response && response.data.code,
            isLoading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              iin,
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
  }

  checkRegistration = async () => {
    const { msisdn, iin, executeRecaptcha } = this.props
    const token = await executeRecaptcha('msisdn_registration')

    RegistrationApi.checkRegistration(msisdn, iin, token).then(({ data }) => {
      const { status } = data
      console.log('checkRegistration', data)
      switch (status) {
        case 'IIN_MATCHED':
        case 'IIN_NOT_MATCHED':
        case 'IIN_CHECKSUM_FAILED':
        case 'AGE_SIXTEEN_RESTRICTED':
        case 'AGE_FORTEEN_RESTRICTED':
        case 'ORG_REGISTERED':
        case 'FAILED':
          this.setState(
            {
              hasError: true,
              errorCode: status,
              isLoading: false,
            },
            () => {
              this.props.setStep(
                'message',
                msisdn,
                iin,
                this.state.hasError,
                this.state.errorCode
              )
            }
          )
          break
        case 'UNREGISTERED':
          this.setState(
            {
              hasError: false,
              errorCode: '',
              isLoading: false,
            },
            () => {
              this.props.setStep(
                'newRegistration',
                msisdn,
                iin,
                this.state.hasError,
                this.state.errorCode
              )
            }
          )
          break
        case 'DEALER_REGISTERED':
          this.setState(
            {
              hasError: false,
              errorCode: '',
              isLoading: false,
            },
            () => {
              this.props.setStep(
                'reRegistration',
                msisdn,
                iin,
                this.state.hasError,
                this.state.errorCode
              )
            }
          )
      }
    })
  }

  getMsisdnStateByMsisdn = async () => {
    const { msisdn, iin } = this.props

    RegistrationApi.getMsisdnStateByMsisdn(nexignMsisdn(msisdn))
      .then(({ data }) => {
        const { status } = data

        if (status) {
          this.setState(
            {
              hasError: false,
              errorCode: '',
              isLoading: false,
            },
            () => {
              this.props.setStep(
                'newRegistration',
                msisdn,
                iin,
                this.state.hasError,
                this.state.errorCode
              )
            }
          )
        } else {
          this.isSelfReregistrationEligible()
        }
      })
      .catch(({ response }) => {
        this.setState(
          {
            hasError: true,
            errorCode: response && response.data.code,
            isLoading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              iin,
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
  }

  getMsisdnStateByMsisdnNonResident = () => {
    const { msisdn, iin, data } = this.props

    RegistrationApi.getMsisdnStateByMsisdn(nexignMsisdn(msisdn))
      .then((response) => {
        const { status } = response.data

        if (status) {
          this.setState(
            {
              hasError: false,
              errorCode: '',
              isLoading: false,
            },
            () => {
              this.props.setStep(
                'newNonRegistration',
                msisdn,
                iin,
                this.state.hasError,
                this.state.errorCode,
                data
              )
            }
          )
        } else {
          this.setState(
            {
              isActivMsisdn: false,
              hasError: true,
              errorCode: 'FAILED',
              isLoading: false,
            },
            () => {
              this.props.setStep(
                'message',
                msisdn,
                '',
                this.state.hasError,
                this.state.errorCode
              )
            }
          )
        }
      })
      .catch(({ response }) => {
        this.setState(
          {
            hasError: true,
            errorCode: response && response.data.code,
            isLoading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              iin,
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
  }

  render() {
    const { msisdn, lang } = this.props
    const { code, isLoading, isResendCodeAllowed, resendSMSIn } = this.state

    return (
      <Row className="code-sent">
        <Col xs={24} sm={24} md={8}>
          <h1 className="mb-24">{Message.SMSCode[lang]}</h1>
          <p className="mb-24">
            {Message.phoneNumber[lang]}: {msisdnWithMask(msisdn)}.
          </p>
          <Form onSubmit={this.handleSubmit}>
            <Form.Item>
              <MaskedInput
                autoFocus
                mask="1111"
                type="tel"
                placeholder={Message.SMSCode[lang]}
                name="code"
                onChange={this.onChangeSmsCode}
              />
              <p className="text-small text-gray mb-0">
                {Message.inputSMSCode[lang]}
              </p>
            </Form.Item>
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={!(code.length === 4)}
                className="check-otp-btn check-registration-btn mb-24"
              >
                {Message.confirm[lang]}
              </Button>
              <div className="send-sms-code">
                {isResendCodeAllowed ? (
                  <p className="link-text" onClick={this.resendCode}>
                    {Message.sendAgain[lang]}
                  </p>
                ) : (
                  <>
                    <p>{Message.sendAgain[lang]}</p>
                    <Countdown
                      value={resendSMSIn}
                      format="mm:ss"
                      onFinish={this.onFinish}
                    />
                  </>
                )}
              </div>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
}

const SmsCodeWrapper = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  return <SmsCode {...props} executeRecaptcha={executeRecaptcha} />
}

export default SmsCodeWrapper
