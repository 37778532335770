// window.__env__ is used in deployed to kube projects
// process.env is used during local development
let envSource

if (process.env.NODE_ENV === 'development') {
  envSource = process.env
} else {
  envSource = window.__env__
}

const { REACT_APP_CONFIG, REACT_APP_SELF_REGISTRATION_URL } = envSource

if (REACT_APP_CONFIG === 'prod' || REACT_APP_CONFIG === 'pre') {
  console.log = () => false
  console.warn = () => false
  console.error = () => false
  console.group = () => false
  console.groupEnd = () => false
}

export default {
  selfRegistrationUrl: REACT_APP_SELF_REGISTRATION_URL,
}
