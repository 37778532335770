import React from 'react'
import { Icon, Layout, Tooltip } from 'antd'
import Message from '../../locale/Message'
import './footer.scss'

const { Footer } = Layout
const isMobile = global.window.innerWidth < 768

const RegistrationFooter = ({ lang }) => (
  <Footer className="footer">
    <section className="contacts">
      <article>
        <div className="phone">
          <Icon type="mobile" className="text-gray" />
          {isMobile ? <a href="tel:116">116</a> : <span>116</span>}
        </div>
        <p className="text-small text-gray mb-0 mt-4">
          {Message.mobileFree[lang]}
        </p>
      </article>
      <article>
        <div className="phone">
          <Icon type="phone" className="text-gray" />
          {isMobile ? (
            <a href="tel:+77272588300">+7 727 258 83 00</a>
          ) : (
            <span>+7 727 258 83 00</span>
          )}
        </div>
        <p className="text-small text-gray mb-0 mt-4">
          {Message.ktTariffs[lang]}
        </p>
      </article>
    </section>
    <section className="copyright text-small">
      {Message.copyright[lang]}
      {isMobile ? (
        <Tooltip title={Message.copyrightDesc[lang]}>
          <Icon type="info-circle" />
        </Tooltip>
      ) : (
        Message.copyrightDesc[lang]
      )}
    </section>
  </Footer>
)

export default RegistrationFooter
