import React, { Component } from 'react'
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  Select,
  AutoComplete,
  Checkbox,
} from 'antd'
import MaskedInput from 'antd-mask-input'
import { nexignMsisdn } from '../../utils'
import CitiesApi from '../../api/CitiesApi'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Message from '../../locale/Message'
import RegistrationApi from '../../api/RegistrationApi'

const { Option } = Select

class NonResidentRegistration extends Component {
  state = {
    sender: 'activ',
    isActivMsisdn: false,
    hasError: false,
    errorCode: null,
    cities: [],
    agreement: false,
  }

  changeAgreement = (agreement) => this.setState({ agreement })

  componentDidMount() {
    this.getCitiesInfo()
  }

  getCitiesInfo = () => {
    const { lang } = this.props

    CitiesApi.getCities()
      .then(({ data }) => {
        this.setState({
          cities: data.map((city) => {
            const regionNameRu = city.region.name.ru
            const regionNameKk = city.region.name.kk
              ? city.region.name.kk
              : regionNameRu
            const regionNameEn = city.region.name.en
              ? city.region.name.en
              : regionNameRu

            return (
              <Option key={city.cityId}>
                {lang === 'ru'
                  ? city.name.ru + ' (' + regionNameRu + ')'
                  : lang === 'en'
                  ? city.name.en + ' (' + regionNameEn + ')'
                  : city.name.kk + ' (' + regionNameKk + ')'}
              </Option>
            )
          }),
        })
      })
      .catch((err) => {
        console.log('getCities error', err.response)
      })
  }

  register = async () => {
    this.setState({
      isLoading: true,
    })

    const { msisdn, iin, executeRecaptcha, data } = this.props
    const token = await executeRecaptcha('new_registration')

    RegistrationApi.subscriberSelfRegistrationNonResident(
      nexignMsisdn(data.msisdn),
      data.docNumber,
      token,
      data.firstName,
      data.lastName,
      data.middleName,
      data.gender === 'MALE' ? 1 : 0,
      data.cityId
    )
      .then((response) => {
        console.log('response', response)
        this.setState(
          {
            hasError: false,
            errorCode: null,
            isloading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              iin,
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
      .catch(({ response }) => {
        console.log('response', response)
        this.setState(
          {
            hasError: true,
            errorCode: '',
            isloading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              iin,
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.register()
  }

  render() {
    const { cities, isLoading, agreement } = this.state
    const { lang, data } = this.props

    return (
      <div>
        <Row>
          <Col xs={24} sm={24} md={8}>
            <h1 className="mb-24">
              {Message.nonresidentNumberRegistration[lang]}
            </h1>
          </Col>
        </Row>
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <MaskedInput
                  autoFocus
                  mask="111 111 11 11"
                  type="tel"
                  addonBefore="+7"
                  name="msisdn"
                  disabled
                  value={nexignMsisdn(data ? data.msisdn : '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <Input
                  type="text"
                  name="firstName"
                  disabled
                  value={data ? data.firstName : ''}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <Input
                  type="text"
                  name="lastName"
                  disabled
                  value={data ? data.lastName : ''}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <Input
                  type="text"
                  name="middleName"
                  disabled
                  value={data ? data.middleName : ''}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <Input
                  type="text"
                  name="docNumber"
                  disabled
                  value={data ? data.docNumber : ''}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <Select
                  name="gender"
                  defaultValue="MALE"
                  disabled
                  value={data ? data.gender : ''}
                >
                  <Option value="MALE">{Message.male[lang]}</Option>
                  <Option value="FEMALE">{Message.female[lang]}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <AutoComplete
                  name="cityId"
                  allowClear={true}
                  disabled
                  value={data ? data.cityId : 0}
                >
                  {cities}
                </AutoComplete>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={16}>
              <Form.Item>
                <Checkbox
                  onChange={() => this.changeAgreement(!agreement)}
                  style={{ lineHeight: 1.5, fontSize: 14 }}
                >
                  {Message.agreement[lang]}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={!agreement}
                  className="check-msisdn-btn send-otp-btn"
                >
                  {Message.register[lang]}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

const NewNonResidentRegistration = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  return (
    <NonResidentRegistration {...props} executeRecaptcha={executeRecaptcha} />
  )
}

export default NewNonResidentRegistration
