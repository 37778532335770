import React, { Component } from 'react'
import { Form, Tabs } from 'antd'
import RegistrationHeader from '../../components/header/RegistrationHeader'
import RegistrationFooter from '../../components/footer/RegistrationFooter'
import MsisdnRegistration from '../../pages/registration/MsisdnRegistration'
import SMSCode from '../../pages/registration/SMSCode'
import NewRegistration from '../registration/NewRegistration'
import ReRegistration from '../registration/ReRegistration'
import NonResidentRegistration from '../registration/NonResidentRegistration'
import RegistrationMessage from '../message/RegistrationMessage'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import './dashboard.scss'
import { getFromStorage } from '../../utils/webStorage'
import Message from '../../locale/Message'
import NewNonResidentRegistration from '../registration/NewNonResidentRegistration'

const { TabPane } = Tabs
const WrappedMsisdnRegistration = Form.create({ name: 'msisdn_registrarion' })(
  MsisdnRegistration
)
const WrappedSMSCode = Form.create({ name: 'sms_code' })(SMSCode)
const WrappedReRegistration = Form.create({ name: 'reregistration' })(
  ReRegistration
)
const WrappedNewNonResidentRegistration = Form.create({
  name: 'newnonresidentregistration',
})(NewNonResidentRegistration)
const RECAPTCHA_API_KEY = '6LcDaKkUAAAAAHaqJHUvdMHdlNkduM-p0L644U8v'

class Dashboard extends Component {
  state = {
    step: '',
    clientType: 'resident',
    msisdn: '',
    iin: '',
    hasError: false,
    errorCode: null,
    lang: getFromStorage('APP_LANG') || 'ru',
    data: {},
    // Является ли номер со старого биллинга (CODA)
    isOldBilling: false,
  }

  setStep = (step, msisdn, iin, hasError, errorCode, data = null) => {
    this.setState({
      step,
      msisdn,
      iin,
      hasError,
      errorCode,
      data,
    })
  }

  // Если биллинг старый, нужно указать
  setThatIsOldBilling = (isOldBilling) => this.setState({ isOldBilling })

  setLanguage = (lang) => {
    this.setState({
      lang,
    })
  }

  changeTab = (activeKey) => {
    this.setState({
      step: activeKey,
      clientType: activeKey,
    })
  }

  goToMain = () => {
    this.setState({
      step: this.state.clientType,
      msisdn: '',
      iin: '',
      hasError: false,
      errorCode: null,
    })
  }

  renderForm = (step) => {
    const {
      msisdn,
      iin,
      hasError,
      errorCode,
      lang,
      clientType,
      data,
      isOldBilling,
    } = this.state

    switch (step) {
      case 'smsCode':
        return (
          <WrappedSMSCode
            lang={lang}
            msisdn={msisdn}
            iin={iin}
            clientType={clientType}
            setStep={this.setStep}
            data={data}
            isOldBilling={isOldBilling}
          />
        )
      case 'newRegistration':
        return (
          <NewRegistration
            lang={lang}
            msisdn={msisdn}
            iin={iin}
            setStep={this.setStep}
            isOldBilling={isOldBilling}
          />
        )
      case 'reRegistration':
        return (
          <WrappedReRegistration
            lang={lang}
            msisdn={msisdn}
            iin={iin}
            setStep={this.setStep}
            isOldBilling={isOldBilling}
          />
        )
      case 'newNonRegistration':
        return (
          <WrappedNewNonResidentRegistration
            lang={lang}
            data={data}
            setStep={this.setStep}
            executeRecaptcha={this.executeRecaptcha}
          />
        )
      case 'message':
        return (
          <RegistrationMessage
            lang={lang}
            msisdn={msisdn}
            messageType={hasError ? 'error' : ''}
            messageDesc={errorCode}
            goToMain={this.goToMain}
          />
        )
      case 'resident':
        return (
          <WrappedMsisdnRegistration
            lang={lang}
            setStep={this.setStep}
            setThatIsOldBilling={this.setThatIsOldBilling}
          />
        )
      case 'non-resident':
        return (
          <NonResidentRegistration
            lang={lang}
            setStep={this.setStep}
            setThatIsOldBilling={this.setThatIsOldBilling}
          />
        )
      default:
        return (
          <WrappedMsisdnRegistration
            lang={lang}
            setStep={this.setStep}
            setThatIsOldBilling={this.setThatIsOldBilling}
          />
        )
    }
  }

  render() {
    const { step, lang, clientType } = this.state

    return (
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_API_KEY} language={'ru'}>
        <div className="dashboard">
          <RegistrationHeader setLanguage={this.setLanguage} />
          <main className="main-container">
            <Tabs
              activeKey={clientType}
              animated={false}
              onChange={this.changeTab}
            >
              <TabPane tab={Message.resident[lang]} key="resident">
                {this.renderForm(step)}
              </TabPane>
              <TabPane tab={Message.nonResident[lang]} key="non-resident">
                {this.renderForm(step)}
              </TabPane>
            </Tabs>
          </main>
          <RegistrationFooter lang={lang} />
        </div>
      </GoogleReCaptchaProvider>
    )
  }
}

export default Dashboard
