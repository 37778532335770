import axios from 'axios'
import conf from '../config'

axios.defaults.baseURL = `${conf.selfRegistrationUrl}`

export default class BaseApi {
  static get(urlPath, params) {
    return axios({
      method: 'GET',
      url: urlPath,
      params: params,
    })
  }

  static post(urlPath, data) {
    return axios({
      method: 'POST',
      url: urlPath,
      data,
    })
  }

  static put(urlPath, data) {
    return axios({
      method: 'PUT',
      url: urlPath,
      data,
    })
  }
}
