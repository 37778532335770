import React from 'react'

export default {
  nonresidentNumberRegistration: {
    ru: 'Регистрация номера',
    en: 'Registration number',
    kk: 'Нөмірді тіркеу',
  },
  numberRegistration: {
    ru: 'Регистрация/перерегистрация номера',
    en: 'Number registration/reregistration',
    kk: 'Нөмір тіркеу/қайта тіркеу',
  },
  selfIINRegistration: {
    ru: 'Оформите номер на Ваш ИИН.',
    en: 'Make a number for your IIN.',
    kk: 'Нөмірді өз ЖСН-іңізге тіркеңіз.',
  },
  inputPhoneNumber: {
    ru: 'Введите Ваш номер',
    en: 'Enter your number',
    kk: 'Нөміріңізді енгізіңіз',
  },
  PhoneNumberForSMS: {
    ru: 'На данный номер будет отправлен SMS-код для подтверждения',
    en: 'An SMS code will be sent to this number to confirm',
    kk: 'Осы нөмірге растау үшін SMS-код жолданады',
  },
  next: {
    ru: 'Далее',
    en: 'Further',
    kk: 'Ары қарай',
  },
  SMSCode: {
    ru: 'Ввод кода из SMS',
    en: 'Enter code from SMS',
    kk: 'SMS-тегі кодты енгізу',
  },
  SMSCodeDesc: {
    ru: `Код []. Не сообщайте этот код никому, даже сотруднику компании, данный код для регистрации/перерегистрации Вашего номера!`,
    en: `Code []. Do not share this number registration code with anyone, including your company employees!`,
    kk: `Код []. Осы кодты ешкімге, тіпті компания қызметкеріне айтпаңыз, бұл код нөміріңізді тіркеуге/қайта тіркеуге арналған!`,
  },
  phoneNumber: {
    ru: 'Номер',
    en: 'Number',
    kk: 'Нөмір',
  },
  IIN: {
    ru: 'ИИН',
    en: 'IIN',
    kk: 'ЖСН',
  },
  inputSMSCode: {
    ru: 'Введите код, полученный по SMS',
    en: 'Enter the code received by SMS',
    kk: 'SMS арқылы алынған кодты енгізіңіз',
  },
  wrongSMSCode: {
    ru: 'Вы ввели неверный проверочный код.',
    en: 'You entered the wrong verification code.',
    kk: 'Сіз қате тексеру кодын енгіздіңіз.',
  },
  wrongIIN: {
    ru: 'Вы ввели неверный ИИН.',
    en: 'You entered the wrong IIN.',
    kk: 'Сіз қате ЖСН енгіздіңіз.',
  },
  wrongPaymentDate: {
    ru: 'Неверная дата последней оплаты.',
    en: 'Last payment date is incorrect.',
    kk: 'Соңғы төлем күні қате.',
  },
  wrongActivationDate: {
    ru: 'Неверная дата активации номера.',
    en: 'Number activation date is incorrect.',
    kk: 'Нөмірді іске қосу күні қате.',
  },
  confirm: {
    ru: 'Подтвердить',
    en: 'Confirm',
    kk: 'Растау',
  },
  sendAgain: {
    ru: 'Отправить повторно ',
    en: 'Resend ',
    kk: 'Қайта жолдау ',
  },
  IINRegistration: {
    ru: 'Регистрация ИИН',
    en: 'IIN registration',
    kk: 'ЖСН тіркеу',
  },
  inputIINForRegistration: {
    ru: 'Для регистрации номера введите Ваш ИИН.',
    en: 'To register this number, enter your IIN.',
    kk: 'Нөмірді тіркеу үшін ЖСН-іңізді енгізіңіз.',
  },
  inputIIN: {
    ru: 'Введите Ваш ИИН',
    en: 'Enter your IIN',
    kk: 'ЖСН енгізіңіз',
  },
  agreement: {
    ru: 'Подтверждаю данные. Согласен с условиями договора',
    en: 'Подтверждаю данные. Согласен с условиями договора',
    kk: 'Подтверждаю данные. Согласен с условиями договора',
  },
  confirmationPublicTerms: {
    ru: (
      <span>
        Нажимая кнопку "Зарегистрировать" Вы присоединяетесь к условиям
        Публичного договора на оказание услуг связи в целом, а также выражаете
        безусловное согласие с его условиями. Публичный договор размещен на
        сайте{' '}
        <a
          href="https://activ.kz/ru/article/public-agreement"
          target="_blank"
          rel="noreferrer noopener"
        >
          activ
        </a>
        . В случае, если УСТРОЙСТВО зарегистрировано не на Ваш ИИН, вам нужно
        перерегистрировать устройство (*660*3#ОК). Если используемое устройство
        не зарегистрировано, введенный ИИН будет использован для регистрации
        этого устройства.
      </span>
    ),
    en: (
      <span>
        By clicking "Continue", you confirm that you have read the terms of the
        Public Mobile Services Agreement and express your unconditional consent
        to be bound by its terms and conditions. Public Mobile Services
        Agreement is available at{' '}
        <a
          href="https://activ.kz/en/article/public-agreement"
          target="_blank"
          rel="noreferrer noopener"
        >
          activ
        </a>
        . If DEVICE is not registered to your IIN, you need to re-register it by
        calling *660*3#. If device is not registered, the IIN you have entered
        will be used to register this device.
      </span>
    ),
    kk: (
      <span>
        “Жалғастыру” пернесін басу арқылы сіз байланыс қызметтерін көрсетуге
        жария шартпен танысқаныңызды растайсыз, оның шарттарымен сөзсіз
        келісіміңізді білдіресіз және жалпы алғанда оған қосыласыз. Жария шарт{' '}
        <a
          href="https://activ.kz/kk/article/public-agreement"
          target="_blank"
          rel="noreferrer noopener"
        >
          activ
        </a>{' '}
        сайтында орналастырылған. Егер ҚҰРЫЛҒЫ сіздің ЖСН-ге тіркелмеген болса,
        құрылғыны қайта тіркеуіңіз қажет (*660*3#ОК). Пайдаланылатын құрылғы
        тіркелмеген болса, енгізілген ЖСН осы құрылғыны тіркеу үшін қолданылады.
      </span>
    ),
  },
  register: {
    ru: 'Зарегистрировать',
    en: 'Register',
    kk: 'Тіркеу',
  },
  thanks: {
    ru: 'Спасибо!',
    en: 'Thank you!',
    kk: 'Рақмет!',
  },
  successRequest: {
    ru: 'Запрос отправлен.',
    en: 'Request has been sent.',
    kk: 'Сұраным жіберілді.',
  },
  successRequestDesc: {
    ru: 'Обработка запроса может занять некоторое время. Ожидайте ответа по SMS.',
    en: 'It may take some time to processing your request. Please, wait for the SMS response.',
    kk: 'Сұранымды өңдеу біраз уақытты алуы мүмкін SMS арқылы жауап күтіңіз.',
  },
  alreadyRegistered: {
    ru: (
      <span>
        Уважаемый абонент!
        <br />
        Этот номер уже зарегистрирован за введенным ИИН.
      </span>
    ),
    en: (
      <span>
        Dear customer!
        <br />
        This number is already registered with the IIN you have entered.
      </span>
    ),
    kk: (
      <span>
        Құрметті абонент!
        <br />
        Бұл нөмір енгізілген ЖСН нөміріне тіркелген.
      </span>
    ),
  },
  goBack: {
    ru: 'Вернуться назад',
    en: 'Back',
    kk: 'Артқа оралу',
  },
  attention: {
    ru: 'Внимание!',
    en: 'Attention!',
    kk: 'Назар аударыңыз!',
  },
  error: {
    ru: 'Ошибка!',
    en: 'Error!',
    kk: 'Қате!',
  },
  onlyActiv: {
    ru: 'Вы не являетесь абонентом activ.',
    en: 'You are not an activ customer.',
    kk: 'Сіз activ абоненті емессіз.',
  },
  orgRegistered: {
    ru: 'Данная услуга Вам недоступна.',
    en: 'This service is not available to you.',
    kk: 'Сізге бұл қызмет қолжетімді болмайды.',
  },
  personalPhoneNumberRegistration: {
    ru: 'Регистрация номера на владельца',
    en: 'Number registration to owner ',
    kk: 'Нөмірді иесінің атына тіркеу',
  },
  reregistrationIIN: {
    ru: 'Для перегистрации номера введите Ваш ИИН.',
    en: 'To change ownership, enter your IIN.',
    kk: 'Нөмірді қайта тіркеу үшін ЖСН-іңізді енгізіңіз.',
  },
  checkPhoneOwner: {
    ru: 'Подтвердите, что вы являетесь владельцем номера.',
    en: 'Confirm that you are the owner of this number.',
    kk: 'Нөмір иесі екеніңізді растаңыз.',
  },
  additionalInfo: {
    ru: 'Пожалуйста, заполните дополнительную информацию, что бы мы были уверены, что форма заполняется владельцем номера.',
    en: 'Please, fill out additional information so that we are sure that the form is filled in by the owner of the number.',
    kk: 'Нысанды нөмір иесінің толтырып жатқанына сенімді болуымыз үшін қосымша ақпаратты енгізуді сұраймыз.',
  },
  inputAdditionalPhoneNumbers: {
    ru: 'Введите 4 номера, на которые вы ранее звонили, за исключением последний 72 часов.',
    en: 'Enter 4 numbers you called more than 72 hours ago.',
    kk: 'Соңғы 72 сағатты қоспағанда бұрын қоңырау шалған 4 нөмірді енгізіңіз.',
  },
  inputLastPaymentInfo: {
    ru: 'Укажите сумму и дату последнего пополнения баланса.',
    en: 'Indicate the amount and date of your last top up.',
    kk: 'Теңгерімнің соңғы толтырылған сомасы мен күнін көрсетіңіз.',
  },
  inputLastPaymentFormat: {
    ru: 'Формат: День.Месяц.Год',
    en: 'Format: Day.Month.Year',
    kk: 'Формат: Күн.Ай.Жыл',
  },
  lastPaymentAmount: {
    ru: 'Сумма последней оплаты',
    en: 'Amount of your last payment',
    kk: 'Соңғы төлем сомасы',
  },
  lastPaymentDate: {
    ru: 'Дата последней оплаты',
    en: 'Last payment date',
    kk: 'Соңғы төлем күні',
  },
  activationDate: {
    ru: 'Укажите дату активации номера.',
    en: 'Indicate date of number activation.',
    kk: 'Нөмірдің іске қосылған күні көрсетіңіз.',
  },
  activationYearAndMonth: {
    ru: 'Укажите месяц и год активации номера.',
    en: 'Indicate the month and year of number activation.',
    kk: 'Нөмірдің іске қосылған айын және жылын көрсетіңіз.',
  },
  activationYearAndMonthFormat: {
    ru: 'Формат: Месяц.Год',
    en: 'Format: Month.Year',
    kk: 'Формат: Ай.Жыл',
  },
  activationYear: {
    ru: 'Укажите год активации номера.',
    en: 'Indicate the year of number activation.',
    kk: 'Нөмірдің іске қосылған жылын көрсетіңіз.',
  },
  activationYearFormat: {
    ru: 'Формат: Год',
    en: 'Format: Year',
    kk: 'Формат: Жыл',
  },
  year: {
    ru: 'год',
    en: 'year',
    kk: 'жыл',
  },
  month: {
    ru: 'месяц',
    en: 'month',
    kk: 'ай',
  },
  systemError: {
    ru: 'Системная ошибка. Попробуйте позднее.',
    en: 'System error. Please try later.',
    kk: 'Жүйе қатесі. Кейінірек көріңіз.',
  },
  systemErrorTitle: {
    ru: 'Системная ошибка!',
    en: 'System Error!',
    kk: 'Жүйе қатесі!',
  },
  tryLater: {
    ru: 'Попробуйте позднее.',
    en: 'Please try later.',
    kk: 'Кейінірек көріңіз.',
  },
  ktTariffs: {
    ru: 'По тарифам «Казахтелеком»',
    en: 'The rates of «Kazakhtelecom» apply.',
    kk: '«Қазақтелеком» тарифтері бойынша',
  },
  mobileFree: {
    ru: 'Бесплатно с мобильных',
    en: 'Free from mobile',
    kk: 'Ұялыдан тегін',
  },
  copyright: {
    ru: '© АО «Кселл» 2000–2019.',
    en: '© Kcell JSC 2000–2019.',
    kk: '© «Кселл» АҚ 2000-2019.',
  },
  copyrightDesc: {
    ru: '© АО «Кселл» 2000–2019. Государственная лицензия МТК № ДС 0000270 от 08.06.1998, бессрочная, выдана Комитетом связи, информатизации и информации Министерства по инвестициям и развитию Республики Казахстан',
    en: '© «Kcell» JSC 2000-2019. State License МТК #ДС 0000270 of 06.06.1998, perpetual, issued by Committee for Communications, Informatization and Information of the Ministry for Investment and Development of the Republic of Kazakhstan',
    kk: '© «Кселл» 2000-2019 Қазақстан Республикасы Инвестициялар және даму министрлігінің Байланыс, ақпараттандыру және ақпарат комитеті берген 08.06.1998 ж. № ДС 0000270 ККМ мемлекеттік лицензиясы, мерзімі шектелмеген',
  },
  ageForteenRestricted: {
    ru: 'Вы пытаетесь зарегистрировать устройство на гражданина младше 14 лет. Регистрацию для данной категории граждан необходимо произвести на ИИН родителя или опекуна',
    en: 'You are trying to register a device to someone under 14 years old. To register a minor’s device, please provide the IIN of his/her parent or guardian.',
    kk: 'Сіз құрылғыны 14 жастан кіші азаматқа тіркеуге тырысып жатырсыз. Азаматтардың осы санаты үшін тіркеу ата-анасының немесе қамқоршысының ЖСН нөміріне жүргізілуі керек.',
  },
  ageSixteenRestricted: {
    ru: (
      <span>
        Вы пытаетесь зарегистрировать устройство на гражданина младше 16 лет.
        Регистрацию для данной категории граждан необходимо произвести в офисе{' '}
        <a href="https://activ.kz/ru/contacts" target="_blanc">
          activ
        </a>{' '}
        в присутствии родителя или опекуна.
      </span>
    ),
    en: (
      <span>
        You are trying to register a device to someone under 16 years old.
        Devices for this category of customers can be registered at any of our
        offices{' '}
        <a href="https://activ.kz/en/contacts" target="_blanc">
          activ
        </a>{' '}
        in the presence of his/her parent or guardian.
      </span>
    ),
    kk: (
      <span>
        Сіз құрылғыны 16 жастан кіші азаматқа тіркеуге тырысып жатырсыз.
        Азаматтардың осы санаты үшін тіркеу ата-анасының немесе қамқоршысының
        көзінше офисте{' '}
        <a href="https://activ.kz/kk/contacts" target="_blanc">
          activ
        </a>{' '}
        жүргізілуі керек.
      </span>
    ),
  },
  registrationIinNotMatched: {
    ru: (
      <span>
        Регистрация номера НЕВОЗМОЖНА! Этот номер зарегистрирован за другим ИИН.
        Для перерегистрации номера необходимо обратиться с оригиналом документа,
        удостоверяющего личности в магазины{' '}
        <a href="https://activ.kz/ru/contacts/about" target="_blanc">
          activ
        </a>
        .
      </span>
    ),
    en: (
      <span>
        Registration of the number is NOT POSSIBLE! This number is registered
        with different IIN. To register it in your name, visit{' '}
        <a href="https://activ.kz/en/contacts/about" target="_blanc">
          activ
        </a>{' '}
        store and make sure to bring your ID with you.
      </span>
    ),
    kk: (
      <span>
        Нөмірді тіркеу МҮМКІН ЕМЕС! Бұл нөмір басқа ЖСН нөміріне тіркелген.
        Нөмірді қайта тіркеу үшін жеке басты куәландыратын құжаттың
        түпнұсқасымен{' '}
        <a href="https://activ.kz/kk/contacts/about" target="_blanc">
          activ
        </a>{' '}
        дүкендеріне келу керек.
      </span>
    ),
  },
  resident: {
    ru: 'Резидент РК',
    en: 'Resident',
    kk: 'ҚР резиденті',
  },
  nonResident: {
    ru: 'Нерезидент РК',
    en: 'Non resident',
    kk: 'ҚР резиденті емес',
  },
  firstName: {
    ru: 'Имя',
    en: 'First name',
    kk: 'Аты',
  },
  lastName: {
    ru: 'Фамилия',
    en: 'Last name',
    kk: 'Тегі',
  },
  middleName: {
    ru: 'Отчество',
    en: 'Middle name',
    kk: 'Әкесінің аты',
  },
  docNumber: {
    ru: 'Номер паспорта',
    en: 'Passport number',
    kk: 'Төлқұжат нөмірі',
  },
  city: {
    ru: 'Населенный пункт',
    en: 'Locality name',
    kk: 'Елді мекен',
  },
  male: {
    ru: 'Мужской',
    en: 'Male',
    kk: 'Ер жынысы',
  },
  female: {
    ru: 'Женский',
    en: 'Female',
    kk: 'Әйел жынысы',
  },
  dearSubscriber: {
    ru: 'Уважаемый абонент',
    en: 'Dear subscriber',
    kk: 'Құрметті абонент',
  },
  addCheck: {
    ru: (
      <span>
        Для регистрации Вашего номера необходимо провести дополнительную
        проверку. Просим Вас посетить офис activ&Kcell с документом,
        удостоверяющим личность. Адреса{' '}
        <a
          href="https://activ.kz/contacts"
          target="_blank"
          rel="noreferrer noopener"
        >
          здесь
        </a>
      </span>
    ),
    en: (
      <span>
        To register your number additional verification is required. We ask you
        to visit activ&Kcell office with your ID.{' '}
        <a
          href="https://activ.kz/contacts"
          target="_blank"
          rel="noreferrer noopener"
        >
          Addresses
        </a>
      </span>
    ),
    kk: (
      <span>
        Нөміріңізді тіркеу үшін қосымша тексеруден өту қажет. activ&Kcell
        кеңсесіне жеке басыңызды куәландыратын құжатпен жолығуды сұраймыз.
        Мекенжайлары{' '}
        <a
          href="https://activ.kz/contacts"
          target="_blank"
          rel="noreferrer noopener"
        >
          мына
        </a>{' '}
        жерде.
      </span>
    ),
  },
}
