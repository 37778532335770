import React, { Component } from 'react'
import { msisdnWithMask, classNameFromCode } from '../../utils'
import Message from '../../locale/Message'

function ErrorComponent({ lang, msisdn }) {
  switch (lang) {
    case 'ru':
      return (
        <span>
          Уважаемый клиент. Номер {msisdn} зарегистрирован на другой ИИН.
          Рекомендуем провести регистрацию номера на основании документа,
          удостоверяющего личность, в ближайшем офисе
          <a
            href="https://activ.kz/contacts"
            target="_blank"
            rel="noreferrer noopener"
          >
            {' activ/Kcell. '}
          </a>
        </span>
      )
    case 'en':
      return (
        <span>
          Dear customer, number {msisdn} is registered with a different IIN
          number. Please, visit an
          <a
            href="https://activ.kz/contacts"
            target="_blank"
            rel="noreferrer noopener"
          >
            {' activ/Kcell. '}
          </a>
          store near you and bring your ID to register this number in your name.
        </span>
      )
    case 'kk':
      return (
        <span>
          Құрметті клиент. {msisdn} нөмірі басқа ЖСН-ге тіркелген. Нөмірді жақын
          орналасқан
          <a
            href="https://activ.kz/contacts"
            target="_blank"
            rel="noreferrer noopener"
          >
            {' activ/Kcell. '}
          </a>
          кеңсесінде жеке басыңызды куәландыратын құжат негізінде тіркеген жөн.
        </span>
      )
    default:
      return null
  }
}

class RegistrationMessage extends Component {
  goToMain = () => {
    this.props.goToMain()
  }

  render() {
    const { msisdn, messageType, messageDesc, lang } = this.props
    const messageClass = messageDesc ? classNameFromCode(messageDesc) : ''

    return (
      <div>
        {messageType === 'error' ? (
          messageDesc && messageDesc.includes('IIN_MATCHED') ? (
            <h2 className="mb-24">{Message.alreadyRegistered[lang]}</h2>
          ) : (
            <h1 className="mb-24">{Message.dearSubscriber[lang]}</h1>
          )
        ) : (
          <h1 className="mb-24">{Message.thanks[lang]}</h1>
        )}
        <p className="mb-24">
          Номер: <b>{msisdnWithMask(msisdn)}</b>
        </p>
        <p className={`mb-24 ${messageClass}`}>
          {messageType === 'error' ? (
            <>
              {messageDesc === 'ONLY_ACTIV' ? (
                Message.onlyActiv[lang]
              ) : messageDesc === 'ORG_REGISTERED' ? (
                Message.orgRegistered[lang]
              ) : messageDesc && messageDesc.includes('IIN_MATCHED') ? (
                ''
              ) : messageDesc && messageDesc.includes('IIN_NOT_MATCHED') ? (
                <ErrorComponent lang={lang} msisdn={msisdnWithMask(msisdn)} />
              ) : messageDesc === 'AGE_FORTEEN_RESTRICTED' ? (
                Message.ageForteenRestricted[lang]
              ) : messageDesc === 'AGE_SIXTEEN_RESTRICTED' ? (
                Message.ageSixteenRestricted[lang]
              ) : messageDesc === 'INCORRECT_IIN' ? (
                Message.wrongIIN[lang]
              ) : messageDesc === 'FAILED' ? (
                Message.addCheck[lang]
              ) : (
                Message.addCheck[lang]
              )}
            </>
          ) : (
            <>
              <p>{Message.successRequest[lang]}</p>
              <p className="success-request">
                {Message.successRequestDesc[lang]}
              </p>
            </>
          )}
        </p>
        <p
          className="text-small text-underline cursor-pointer"
          onClick={this.goToMain}
        >
          {Message.goBack[lang]}
        </p>
      </div>
    )
  }
}

export default RegistrationMessage
