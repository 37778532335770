export const addToStorage = (name, token) => {
  localStorage.setItem(name, token)
}

export const clearFromStorage = () => {
  localStorage.clear()
}

export const getFromStorage = (name) => {
  return localStorage.getItem(name)
}
