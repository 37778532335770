import React, { Component } from 'react'
import { Form, Button, Row, Col } from 'antd'
import MaskedInput from 'antd-mask-input'
import { msisdnWithMask } from '../../utils'
import { pureMsisdn, nexignMsisdn } from '../../utils/index'
import RegistrationApi from '../../api/RegistrationApi'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Message from '../../locale/Message'

class ReRegistration extends Component {
  state = {
    msisdn1: '',
    msisdn2: '',
    msisdn3: '',
    msisdn4: '',
    isFieldsValid: false,
    hasError: false,
    errorCode: null,
    isLoading: false,
  }

  componentDidMount() {
    this.props.form.validateFields()
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.subscriberSelfReregistration()
  }

  reregisterNumber = async () => {
    const { msisdn1, msisdn2, msisdn3, msisdn4 } = this.state
    const { msisdn, iin, executeRecaptcha } = this.props

    this.setState({
      isLoading: true,
    })

    const msisdnsToCheck = [msisdn1, msisdn2, msisdn3, msisdn4]
    const token = await executeRecaptcha('reregistration')

    RegistrationApi.reregister(msisdn, iin, token, msisdnsToCheck)
      .then((response) => {
        console.log('response', response)
        this.setState(
          {
            hasError: false,
            errorCode: null,
            isLoading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              iin,
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
      .catch(({ response }) => {
        console.log('response', response)
        this.setState(
          {
            hasError: true,
            errorCode: '',
            isLoading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              iin,
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
  }

  subscriberSelfReregistration = async () => {
    const { msisdn1, msisdn2, msisdn3, msisdn4 } = this.state
    const { msisdn, iin, executeRecaptcha } = this.props

    this.setState({
      isLoading: true,
    })

    const msisdnsToCheck = [
      nexignMsisdn(msisdn1),
      nexignMsisdn(msisdn2),
      nexignMsisdn(msisdn3),
      nexignMsisdn(msisdn4),
    ]
    const token = await executeRecaptcha('reregistration')

    RegistrationApi.subscriberSelfReregistration(
      nexignMsisdn(msisdn),
      iin,
      msisdnsToCheck,
      token
    )
      .then((response) => {
        console.log('response', response)
        this.setState(
          {
            hasError: false,
            errorCode: null,
            isLoading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              iin,
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
      .catch(({ response }) => {
        console.log('response', response)
        this.setState(
          {
            hasError: true,
            errorCode: '',
            isLoading: false,
          },
          () => {
            this.props.setStep(
              'message',
              msisdn,
              iin,
              this.state.hasError,
              this.state.errorCode
            )
          }
        )
      })
  }

  onChangeInput = (e) => {
    const { name, value } = e.target
    let formValue

    if (name.includes('msisdn')) {
      formValue = pureMsisdn(value)
    }

    this.setState(
      {
        [name]: formValue,
      },
      () => {
        this.isFieldsValid()
      }
    )
  }

  isFieldsValid = () => {
    const { msisdn1, msisdn2, msisdn3, msisdn4 } = this.state

    let isValid =
      msisdn1 &&
      msisdn1.length === 11 &&
      msisdn2 &&
      msisdn2.length === 11 &&
      msisdn3 &&
      msisdn3.length === 11 &&
      msisdn4 &&
      msisdn4.length === 11

    this.setState({
      isFieldsValid: isValid,
    })
  }

  render() {
    const { msisdn, iin, lang } = this.props
    const { isFieldsValid, isLoading } = this.state

    return (
      <div className="correct-code">
        <h1 className="mb-24">
          {Message.personalPhoneNumberRegistration[lang]}
        </h1>
        <p>
          {Message.phoneNumber[lang]}: {msisdnWithMask(msisdn)}.
        </p>
        <p>
          {Message.IIN[lang]}: {iin}.
        </p>
        <Row>
          <Col xs={24} sm={24} md={16}>
            <p className="text-default">{Message.additionalInfo[lang]}</p>
            <p className="text-default">
              {Message.inputAdditionalPhoneNumbers[lang]}
            </p>
          </Col>
        </Row>
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <MaskedInput
                  autoFocus
                  mask="111 111 11 11"
                  type="tel"
                  placeholder={Message.phoneNumber[lang] + ' 1'}
                  addonBefore="+7"
                  name="msisdn1"
                  onChange={this.onChangeInput}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <MaskedInput
                  mask="111 111 11 11"
                  type="tel"
                  placeholder={Message.phoneNumber[lang] + ' 2'}
                  addonBefore="+7"
                  name="msisdn2"
                  onChange={this.onChangeInput}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <MaskedInput
                  mask="111 111 11 11"
                  type="tel"
                  placeholder={Message.phoneNumber[lang] + ' 3'}
                  addonBefore="+7"
                  name="msisdn3"
                  onChange={this.onChangeInput}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <MaskedInput
                  mask="111 111 11 11"
                  type="tel"
                  placeholder={Message.phoneNumber[lang] + ' 4'}
                  addonBefore="+7"
                  name="msisdn4"
                  onChange={this.onChangeInput}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item className="mb-0">
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={!isFieldsValid}
                  loading={isLoading}
                  className="reregistration-btn mb-16"
                >
                  {Message.register[lang]}
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={16}>
              <p className="text-small text-dark-gray">
                {Message.confirmationPublicTerms[lang]}
              </p>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

const ReRegistrationWrapper = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  return <ReRegistration {...props} executeRecaptcha={executeRecaptcha} />
}

export default ReRegistrationWrapper
