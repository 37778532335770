import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Layout, Select, Icon } from 'antd'
import { addToStorage, getFromStorage } from '../../utils/webStorage'
import './header.scss'

const { Header } = Layout
const { Option } = Select

const RegistrationHeader = ({ setLanguage }) => {
  const [lang, setLang] = useState(getFromStorage('APP_LANG') || 'ru')
  const selectIcon = <Icon style={{ color: '#212121' }} type="caret-down" />

  const handleChange = (value) => {
    setLang(value)
    addToStorage('APP_LANG', value)
    setLanguage(value)
    /*window.location.reload(true);*/
  }

  return (
    <Header className="header">
      <Link to="/" className="d-flex">
        <i className="logo myicon activ" />
      </Link>
      <div className="select-lang d-flex align-items-center">
        <i className={`myicon ${lang}`} />
        <Select
          defaultValue={lang}
          suffixIcon={selectIcon}
          onChange={handleChange}
        >
          <Option value="ru">Русский</Option>
          <Option value="kk">Қазақ</Option>
          <Option value="en">English</Option>
        </Select>
      </div>
    </Header>
  )
}

export default RegistrationHeader
