export const msisdnWithMask = (msisdn) => {
  if (!msisdn || typeof msisdn !== 'string') {
    return null
  }
  const msisdnOnlyDigits = msisdn.replace(/[^0-9]/g, '')
  if (msisdnOnlyDigits.length < 11) {
    return null
  }
  return '+7 '
    .concat(msisdnOnlyDigits.slice(1, 4))
    .concat(' ')
    .concat(msisdn.slice(4, 7))
    .concat(' ')
    .concat(msisdn.slice(7, 9))
    .concat(' ')
    .concat(msisdn.slice(9, 11))
}

export const pureMsisdn = (msisdn) => {
  if (!msisdn || typeof msisdn !== 'string') {
    return ''
  }
  const msisdnOnlyDigits = msisdn.replace(/[^0-9]/g, '')

  return '7' + msisdnOnlyDigits
}

export const classNameFromCode = (code) => {
  return code && code.replace('_', '-').toLowerCase()
}

export const nexignMsisdn = (msisdn) => (msisdn + '').replace(/^7/, '')

export const regExpCity = /\s\(.+\)/
